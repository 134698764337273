import { CategoryType } from "./api/categories/useCategories";

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const collect = <T>(value: T): T extends any[] ? T : T[] => {
  return Array.isArray(value) ? value : ([value] as any);
};

export const first = <T>(value: T[]): T | undefined => {
  return value.find((x) => x);
};

export const minutesToMilliseconds = (minutes: number) => {
  return minutes * 60 * 1000;
};

const categoryTypeToDisplayMap: Record<CategoryType, string> = {
  [CategoryType.QUAD_PIPE]: "4pipe",
  [CategoryType.DOUBLE_PIPE]: "2pipe",
  [CategoryType.QUAD_PIPE_OTHER]: "4pipe other",
};

export const categoryTypeToDisplay = (type: CategoryType) => {
  return categoryTypeToDisplayMap[type];
};

export const uri = (baseAddress: string, queryParams?: Record<string, any>) => {
  if (!queryParams) return baseAddress;

  const params = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  return `${baseAddress}?${params}`;
};
