import axios, { AxiosRequestConfig } from "axios";
import { BACKEND_URL } from "../config";
//import Qs from "qs";
const qs = require('qs');

const client = axios.create({
  baseURL: BACKEND_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  paramsSerializer: params => {
    return qs.stringify(params);
  },
});

class Api {
  public get<T = any>(url: string, config?: AxiosRequestConfig) {
    return this.request<T>({ ...config, method: "GET", url });
  }

  public post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.request<T>({ ...config, method: "POST", url, data });
  }

  public put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.request<T>({ ...config, method: "PUT", url, data });
  }

  public delete<T = any>(url: string, config?: AxiosRequestConfig) {
    return this.request<T>({ ...config, method: "DELETE", url });
  }

  private async request<T>(config: AxiosRequestConfig) {
    // if no csrf token cookie and one is needed, try get one
    if (config.method !== "GET" && !document.cookie.includes("XSRF-TOKEN")) {
      try {
        await client.get("/sanctum/csrf-cookie");
      } catch (e) {}
    }

    return client.request<T>(config);
  }
}

export default new Api();
