import { FC, lazy, Suspense } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ErrorNotFound from "./pages/ErrorNotFound";
import { minutesToMilliseconds } from "./utils";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: minutesToMilliseconds(1),
    },
  },
});

const routes = [
  { path: "/", component: lazy(() => import("./pages/index")) },
  {
    path: "/projects",
    component: lazy(() => import("./pages/projects/index")),
  },
  {
    path: "/projects/:id([0-9]+)",
    component: lazy(() => import("./pages/projects/show")),
  },
  {
    path: "/projects/:projectId([0-9]+)/selections/:selectionId(new|[0-9]+)/search",
    component: lazy(() => import("./pages/projects/selections/search")),
  },
  {
    path: "/projects/:projectId([0-9]+)/selections/:selectionId(new|[0-9]+)/results",
    component: lazy(() => import("./pages/projects/selections/results")),
  },
  {
    path: "/projects/:projectId([0-9]+)/selections/:selectionId([0-9]+)",
    component: lazy(() => import("./pages/projects/selections/show")),
  },
  { path: "/account", component: lazy(() => import("./pages/account")) },
  { path: "/dashboard", component: lazy(() => import("./pages/dashboard")) },
  {
    path: "/forgot-password",
    component: lazy(() => import("./pages/forgot-password")),
  },
  { path: "/login", component: lazy(() => import("./pages/login")) },
  {
    path: "/password-reset",
    component: lazy(() => import("./pages/password-reset")),
  },
  { path: "/register", component: lazy(() => import("./pages/register")) },
  { path: "/units/new", component: lazy(() => import("./pages/units/new")) },
  {
    path: "/units/:id([0-9]+)/edit",
    component: lazy(() => import("./pages/units/edit")),
  },
];

const App: FC = () => {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Helmet>
          <meta name="description" content="FCUSelect" />
        </Helmet>

        <Suspense fallback={null}>
          <BrowserRouter>
            <Switch>
              {routes.map(({ path, component: Component }) => {
                return (
                  <Route key={path} exact path={path}>
                    <Component />
                  </Route>
                );
              })}
              <Route path="*">
                <ErrorNotFound />
              </Route>
            </Switch>
          </BrowserRouter>
        </Suspense>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default App;
