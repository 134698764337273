import { useQuery } from "react-query";
import api from "../api";

export enum CategoryType {
  QUAD_PIPE = "QUAD_PIPE",
  DOUBLE_PIPE = "DOUBLE_PIPE",
  QUAD_PIPE_OTHER = "QUAD_PIPE_OTHER",
}

export const CATEGORY_TYPES = [
  CategoryType.QUAD_PIPE,
  CategoryType.DOUBLE_PIPE,
  CategoryType.QUAD_PIPE_OTHER,
];

export interface Category {
  id: number;
  name: string;
  type: CategoryType;
  ducted: boolean;
  heating_only: boolean;
  cooling_only: boolean;
}

export default function useCategories() {
  return useQuery<Category[], Error>("categories", async () => {
    const { data: categories } = await api.get<Category[]>("/api/categories");
    return categories;
  });
}
